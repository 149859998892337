html,
body {
  padding: 0;
  margin: 0;
}

textarea {
  font-family: inherit;
  text-transform: unset;
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiButtonBase-root {
  text-transform: inherit;
}
button {
  text-transform: inherit;
}

p {
  text-transform: initial;
}

* {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #dcdbe0;
  border-radius: 5px;
}

.pac-container {
  z-index: 99999;
}
